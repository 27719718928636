<template>
  <v-container fluid class="non-printable">
    <v-dialog max-width="1100" v-model="show_search_window" v-if="show_search_window">
      <v-card height="76vh">
        <v-card-title class="pt-2 pb-2">
          Пошук контрагента
        </v-card-title>
        <v-card-text>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Пошук"
              color="grey darken-1"
              placeholder="Почніть вводити текст для пошуку"
              single-line
              hide-details
              class="mb-1"
              @input="searchFlatByText"
              filled
          />
        </v-card-text>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="search_flats"
              :items-per-page="15"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                          showFirstLastPage: true,
                          itemsPerPageText: 'Рядків на сторінку',
                          itemsPerPageOptions: [5, 15, 30, -1],

                        }"
              @click:row="openWorkPlace"
              class="custom-table"
          >
            <template v-slot:item.person_id="{ item }">
              <v-icon class="mr-2" color="error" size="22" v-if="item.disable">mdi-alert</v-icon>
              <v-icon class="mr-2" color="secondary" size="22" v-if="item.not_living">mdi-store</v-icon>
              <v-icon class="mr-2" color="grey darken-1" size="22" v-else>mdi-domain</v-icon>
              <span>{{ item.person_id }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row justify="center" style="min-height: 100vh; flex-direction: column">
      <v-col cols="12" class="pt-1" style="flex: 0">
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-card class="pt-2 pb-2 px-3 fill-height"
                    style="border-left: 3px solid #4CAF50"
            >
              <div class="legacy-box">
                <div class="legacy-item">
                  <div class="legacy-value" style="background-color: white; padding: 0">
                    <div class="legacy-value-box">
                      <div class="legacy-value-item" style="flex: 1 1 48%">
                        <div class="legacy-value-item-title">Особовий рахунок</div>
                        <div class="legacy-value-item-value">
                          {{ flat.person_id || '-------' }}
                        </div>
                      </div>
                      <div class="legacy-value-item" style="flex: 1 1 48%">
                        <div class="legacy-value-item-title">Місяць</div>
                        <div class="legacy-value-item-value text-capitalize">
                          {{ current_month | formatDate('MMMM YYYY') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="legacy-item">
                  <div class="legacy-value" style="background-color: white; padding: 0">
                    <div class="legacy-value-box">
                      <div class="legacy-value-item" style="flex: 1 1 48%">
                        <div class="legacy-value-item-title">Бюджет</div>
                        <div class="legacy-value-item-value">Відсутній</div>
                      </div>
                      <div class="legacy-value-item" style="flex: 1 1 48%">
                        <div class="legacy-value-item-title">Оподаткування</div>
                        <div class="legacy-value-item-value">
                          {{ flat.tax_name || 'Невідомо' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card class="pt-2 pb-2 px-3 fill-height"
                    style="border-left: 3px solid #4CAF50"
            >
              <div class="legacy-box">
                <div class="legacy-item">
                  <div class="legacy-title">Найменування</div>
                  <div class="legacy-value">
                    {{ flat.full_address }}
                  </div>
                </div>
                <div class="legacy-item">
                  <div class="legacy-value" style="background-color: white; padding: 0">
                    <div class="legacy-value-box">
                      <div class="legacy-value-item" style="flex: 1 1 32%">
                        <div class="legacy-value-item-title">Код ЄДРПОУ</div>
                        <div class="legacy-value-item-value">
                          {{ flat.code || '-------' }}
                        </div>
                      </div>
                      <div class="legacy-value-item" style="flex: 1 1 32%">
                        <div class="legacy-value-item-title">ІПН</div>
                        <div class="legacy-value-item-value">
                          {{ flat.ident_code || '-------' }}
                        </div>
                      </div>
                      <div class="legacy-value-item" style="flex: 1 1 32%">
                        <div class="legacy-value-item-title">Вид особи</div>
                        <div class="legacy-value-item-value">
                          {{ flat.is_legal_person ? 'Фізична особа' : 'Юридична особа' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card class="pt-2 pb-2 px-3 fill-height"
                    style="border-left: 3px solid #4CAF50"
            >
              <div class="legacy-box">
                <div class="legacy-item">
                  <div class="legacy-title">Фактична адреса</div>
                  <div class="legacy-value">
                    {{ flat.fact_address || '-------' }}
                  </div>
                </div>
                <div class="legacy-item">
                  <div class="legacy-value" style="background-color: white; padding: 0">
                    <div class="legacy-value-box">
                      <div class="legacy-value-item" style="flex: 1 1 48%">
                        <div class="legacy-value-item-title">Керівник</div>
                        <div class="legacy-value-item-value">
                          {{ get_response_person('director') }}
                        </div>
                      </div>
                      <div class="legacy-value-item" style="flex: 1 1 48%">
                        <div class="legacy-value-item-title">Бухгалтер</div>
                        <div class="legacy-value-item-value">
                          {{ get_response_person('chief_accountant') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-0 py-0">
            <v-toolbar elevation="0" class="py-0 grey lighten-4 pr-0 tool">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn medium plain class="mr-2 grey lighten-3"
                         v-bind="attrs" v-on="on"
                         :disabled="!prev_button_visible"
                         @click.stop="nextItem($route.params.person_hash, false)"
                  >
                    <v-icon left size="30">mdi-chevron-left</v-icon>
                    Попередній
                  </v-btn>
                </template>
                <span>Попередній абонент</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn medium plain class="ml-2 grey lighten-3"
                         v-bind="attrs" v-on="on"
                         :disabled="!next_button_visible"
                         @click.stop="nextItem($route.params.person_hash, true)"
                  >
                    <v-icon left size="30">mdi-chevron-right</v-icon>
                    Наступний
                  </v-btn>
                </template>
                <span>Наступний абонент</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-tooltip bottom v-if="!organization.cabinet_only">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="ml-2 success" v-on="on"
                         :loading="calc_loading"
                         v-bind="attrs" @click.stop="calcByFlat" height="42" width="42">
                    <v-icon color="white" size="22">mdi-calculator</v-icon>
                  </v-btn>
                </template>
                <span>Розрахувати нарахування</span>
              </v-tooltip>
              <v-tooltip bottom v-if="$vuetify.breakpoint.smAndUp">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn medium icon plain class="grey lighten-3"
                         :class="$vuetify.breakpoint.xs ? 'ml-1 mr-1' : 'ml-2 mr-2'"
                         v-bind="attrs" v-on="on"
                         @click="openSearchWindow">
                    <v-icon
                        :size="$vuetify.breakpoint.smAndUp ? 22 : 30"
                        color="teal darken-1"
                    >mdi-home-search-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Пошук контрагента</span>
              </v-tooltip>
            </v-toolbar>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="pt-1" style="flex: 1; display: flex">
        <v-card-text class="pa-0">
          <v-tabs class="custom-tabs-2" color="success" :class="$vuetify.breakpoint.xsOnly ? 'hidden' : ''" v-model="tab">
            <v-tab class="text-left justify-start">
              Стан
            </v-tab>
            <v-tab class="text-left justify-start">
              Нормативи та групи
            </v-tab>
            <v-tab class="text-left justify-start">
              Договори
            </v-tab>
            <v-tab class="text-left justify-start">
              Лічильники
            </v-tab>
            <v-tab class="text-left justify-start">
              Розрахунки
            </v-tab>
            <v-tab class="text-left justify-start">
              Документи
            </v-tab>
            <v-tab class="text-left justify-start">
              Інші дані
            </v-tab>
            <v-tab class="text-left justify-start">
              Дані абонента
            </v-tab>

            <v-tab-item class="pa-0">
              <v-row style="flex: 1">
                <v-col cols="12" md="12">
                  <HWL_Accounts :height="'100%'" @fetch="calcAfterChange"/>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0">
              <v-row>
                <v-col cols="12" md="12">
                  <v-card-text class="fill-height py-0 px-0">
                    <v-tabs color="success" class="custom-tabs-1">
                      <v-tab class="py-3">Нормативно-якісні параметри</v-tab>
                      <v-tab class="py-3">Групи тарифів</v-tab>
                      <v-tab class="py-3">Послуги</v-tab>
                      <v-tab class="py-3">Тарифи</v-tab>
                      <v-tab class="py-3">Субабоненти</v-tab>

                      <v-tab-item>
                        <HWL_Normatives @fetch="calcAfterChange"/>
                      </v-tab-item>
                      <v-tab-item>
                        <HWL_TariffGroupsTable @fetch="calcAfterChange"/>
                      </v-tab-item>
                      <v-tab-item>
                        <HWL_FlatServices @fetch="calcAfterChange"/>
                      </v-tab-item>
                      <v-tab-item>
                        <HWL_TariffForFlat @fetch="calcAfterChange"/>
                      </v-tab-item>
                      <v-tab-item>
                        <HWP_FlatCounterSubAccount @fetch="calcAfterChange"/>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0">
              <v-row>
                <v-col cols="12" md="12">
                  <HWL_FlatContract/>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0">
              <v-row style="display: flex; flex-direction: column;">
                <v-col cols="12" md="12" class="pb-1" style="flex: 0">
                  <HWL_FlatCounterLegacy :selected="selected" height="270px"/>
                </v-col>
                <v-col cols="12" md="12" class="pt-1" style="flex: 1">
                  <v-row class="fill-height">
                    <v-col cols="12" md="9" class="fill-height">
                      <HWL_Pokaz :counter_id="selected.length ? selected[0].id : -1" height="100%"/>
                    </v-col>
                    <v-col cols="12" md="3" class="fill-height">
                      <HWP_FlatCounterOperation :counter_id="selected.length ? selected[0].id : -1" height="100%"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row>
                <v-col cols="12" md="12">
                  <HWL_Archive height="100%"/>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row>
                <v-col cols="12" md="12">
                  <HWL_AccountingDocuments height="100%"/>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0">
              <v-row>
                <v-col cols="12" md="12">
                  <v-card-text class="fill-height py-0 px-0">
                    <v-tabs color="success" class="custom-tabs-1">
                      <v-tab class="py-3">Банківські рахунки</v-tab>
                      <v-tab class="py-3">Відповідальні особи</v-tab>

                      <v-tab-item>
                        <HWL_FlatResponsePerson/>
                      </v-tab-item>
                      <v-tab-item>
                        <HWL_FlatBankAccount/>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0">
              <v-row>
                <v-col cols="12">
                  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280">
                    <v-card-title class="pa-0">
                      <v-toolbar elevation="0" class="pa-0">
                        <v-toolbar-title class="toolbar-work-place-title">
                          Дані юр.особи {{ flat.address }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </v-card-title>
                    <v-card-text>
                      <v-tabs color="success" class="custom-tabs-1">
                        <v-tab>Основна інформація</v-tab>
                        <v-tab>Адреса</v-tab>

                        <v-tab-item>
                          <v-row class="pt-6 pb-2">
                            <v-col cols="12" xl="7" md="8" sm="12">
                              <v-form v-model="formValid" ref="form">
                                <v-row class="dense-wrapper">
                                  <v-col cols="12" md="8" sm="7">
                                    <v-text-field type="text"
                                                  filled
                                                  label="Повна назва"
                                                  v-model="flat_form.full_name"
                                                  hide-details
                                                  :disabled="!edit"
                                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                  :class="flat_form.full_name ? '' : 'req-star'"
                                                  color="grey"/>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="5">
                                    <v-checkbox color="success" label="Фізична особа" :disabled="!edit"
                                                hide-details class="mt-2"
                                                v-model="flat_form.is_legal_person"/>
                                  </v-col>
                                  <v-col cols="12" md="8" sm="7">
                                    <v-text-field type="text"
                                                  filled
                                                  label="Коротка назва"
                                                  v-model="flat_form.short_name"
                                                  hide-details
                                                  :disabled="!edit"
                                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                  :class="flat_form.short_name ? '' : 'req-star'"
                                                  color="grey"/>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="5">
                                    <v-text-field type="text"
                                                  filled
                                                  label="Особовий рахунок"
                                                  :disabled="!edit"
                                                  v-model="flat_form.person_id"
                                                  hide-details
                                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                  :class="flat_form.person_id ? '' : 'req-star'"
                                                  color="grey"/>
                                  </v-col>
                                  <v-col cols="6" md="4" sm="6">
                                    <v-text-field type="text"
                                                  filled
                                                  label="Код ЄДРПОУ"
                                                  v-model="flat_form.code"
                                                  hide-details
                                                  :disabled="!edit"
                                                  v-if="!flat_form.is_legal_person"
                                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                  :class="flat_form.code ? '' : 'req-star'"
                                                  color="grey"/>
                                    <v-text-field type="text"
                                                  filled
                                                  label="Код ДРФО"
                                                  v-model="flat_form.ident_code"
                                                  hide-details
                                                  :disabled="!edit"
                                                  v-if="flat_form.is_legal_person"
                                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                  :class="flat_form.ident_code ? '' : 'req-star'"
                                                  color="grey"/>
                                  </v-col>
                                  <v-col cols="6" md="4" sm="6">
                                    <v-select type="text" filled label="Ставка ПДВ" v-model="flat_form.tax_type"
                                              hide-details
                                              :items="taxes"
                                              :disabled="!edit"
                                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                              :class="flat_form.tax_type ? '' : 'req-star'"
                                              color="grey"/>
                                  </v-col>
                                  <v-col cols="6" md="4" sm="6">
                                    <v-text-field type="text"
                                                  filled
                                                  label="Номер свідоцтва ПДВ"
                                                  v-model="flat_form.ipn_tax"
                                                  hide-details
                                                  :disabled="!edit"
                                                  color="grey"/>
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-textarea v-model="flat_form.legal_address"  :disabled="!edit"
                                                label="Юридична адреса" color="grey" filled hide-details auto-grow
                                                rows="1"/>
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-textarea v-model="flat_form.fact_address"  :disabled="!edit"
                                                label="Фактична адреса" color="grey" filled hide-details auto-grow
                                                rows="1"/>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-switch v-model="flat_form.disable" hide-details
                                              :disabled="!edit"
                                              color="success"
                                              :label="flat_form.disable ? 'Закритий особовий рахунок' : 'Актуальний особовий рахунок'"
                                    />
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <date-component label="Дата закриття" v-model="flat_form.disable_date"
                                                    :disabled="!edit"
                                                    :req="flat_form.disable"
                                    />
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-tab-item>
                        <v-tab-item>
                          <v-row class="pt-6 pb-2">
                            <v-col cols="12" xl="7" md="8" sm="12">
                              <v-row>
                                <v-col cols="12" md="12">
                                  <AddressElementSelect v-model="flat_form.city_id" req
                                                        label="Населений пункт" filled
                                                        select_type="city"
                                                        :disabled="!edit"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <AddressElementSelect :parent_id="flat_form.city_id" v-model="flat_form.street_id"
                                                        label="Вулиця(-ки)" filled req
                                                        select_type="street" use_parent_id
                                                        :disabled="!flat_form.city_id || !edit"
                                  />
                                </v-col>
                                <v-col cols="6" md="8" style="display: flex">
                                  <AddressElementSelect :parent_id="flat_form.street_id" v-model="flat_form.building_id"
                                                        label="Будинок(-ки)" filled req
                                                        select_type="building" use_parent_id
                                                        :disabled="!flat_form.street_id || !edit"
                                                        style="flex: 1"
                                                        @selectChanged="buildingChange"
                                  />
                                  <MainModal
                                      :main="{ component: 'Building', title: 'Будинки' }"
                                      :button="{ icon: false, color: 'secondary', buttonIcon: 'mdi-plus', buttonHidden: !edit, buttonDepressed: true, buttonClass: 'ml-2 grey lighten-3', buttonStyle: 'height: 100%' }"
                                      @updateItemModal="closeModalEmit"
                                  />
                                </v-col>
                                <v-col cols="6" md="4">
                                  <v-text-field type="text"
                                                filled
                                                label="Номер приміщення"
                                                v-model="flat_form.flat_number"
                                                hide-details
                                                :disabled="!edit"
                                                color="grey"/>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-tab-item>
                      </v-tabs>
                      <v-row class="pt-4">
                        <v-col cols="12" xl="7" md="8" sm="12" @click="flatSaveOrUpdate">
                          <v-btn block :color="edit ? 'success' : 'secondary'" height="50" :loading="update_loading">
                            <v-icon left dark size="24" class="mr-3">
                              {{ edit ? 'mdi-content-save-outline' : 'mdi-circle-edit-outline' }}
                            </v-icon>
                            {{ edit ? 'Зберегти' : 'Редагувати' }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

          </v-tabs>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {GET_LEGACY_FLAT, NEXT_LEGACY_ITEM, UPDATE_LEGACY_FLAT} from "@/store/actions/flat";
import contractorAPI from "@/utils/axios/accounting/contractor"
import {SELECT_TAXES_LIST_ELEMENTS} from "@/store/actions/accounting/list";
import {CALC_BY_FLAT_LEGACY} from "@/store/actions/calculation";
import {
  GET_ALL_WITH_BALANCE_LEGACY,
  GET_ARCHIVE_LEGACY
} from "@/store/actions/flat_service";
import {ALERT_SHOW} from "@/store/actions/alert";
import flatAPI from "@/utils/axios/flat";

export default {
  name: "HousePayWorkplaceLegacy",
  components: {
    HWL_Accounts: () => import('@/components/HouseLegacy/HWL_Accounts'),
    HWL_Normatives: () => import("@/components/HouseLegacy/HWL_Normatives"),
    HWL_TariffGroupsTable: () => import("@/components/HouseLegacy/HWL_TariffGroupsTable"),
    HWL_FlatContract: () => import("@/components/HouseLegacy/HWL_FlatContract"),
    HWL_FlatResponsePerson: () => import("@/components/HouseLegacy/HWL_FlatResponsePerson"),
    HWL_FlatBankAccount: () => import("@/components/HouseLegacy/HWL_FlatContractorBankAccount"),
    HWL_FlatCounterLegacy: () => import("@/components/FlatCounterLegacy"),
    HWL_Pokaz: () => import("@/components/HouseLegacy/HWL_Pokaz"),
    HWL_Archive: () => import("@/components/HouseLegacy/HWL_Archive"),
    HWL_AccountingDocuments: () => import("@/components/HouseLegacy/HWP_AccountingDocument"),
    HWL_FlatServices: () => import("@/components/HouseLegacy/HWL_FlatServices.vue"),
    HWL_TariffForFlat: () => import("@/components/HouseLegacy/HWL_TariffForFlat.vue"),
    HWP_FlatCounterSubAccount: () => import("@/components/HouseWorkPlace/HWP_FlatCounterSubAccount.vue"),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    HWP_FlatCounterOperation: () => import("@/components/HouseWorkPlace/HWP_FlatCounterOperation"),
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      flat: 'getLegacyFlat',
      current_month: 'getCurrentMonthLegacy',
      organization: 'getCurrentOrganization',
      userId: 'getUserId',
      taxes: 'get_taxes_list_pdv_select',
    })
  },
  data() {
    return {
      formValid: false,
      edit: false,
      show_building_create: false,
      flat_form: {
        full_name: null,
        is_legal_person: false,
        short_name: null,
        person_id: null,
        code: null,
        ident_code: null,
        tax_type: null,
        ipn_tax: null,
        city_id: null,
        street_id: null,
        building_id: null,
        flat_number: null,
        legal_address: null,
        fact_address: null,
        disable: false,
        disable_date: null,
      },
      calc_loading: false,
      tab: null,
      selected: [],
      print_data: {
        bank_account: {},
        response_persons: {},
        print_data: ''
      },
      reload_buildings: false,
      update_loading: false,
      prev_button_visible: true,
      next_button_visible: true,
      show_search_window: false,
      search: null,
      search_flats: [],
      headers: [
        {text: 'Особовий рах.', value: 'person_id', width: 150},
        {text: 'Найменування', value: 'full_address'},
        {text: 'Адреса', value: 'fact_address'},
        {text: 'ЄДРПОУ', value: 'code', width: 120},
        {text: 'ІПН', value: 'ident_code', width: 120},
      ],
    }
  },
  methods: {
    ...mapActions({
      fetchOne: GET_LEGACY_FLAT,
      calculateByFlat: CALC_BY_FLAT_LEGACY,
      fetchTaxes: SELECT_TAXES_LIST_ELEMENTS,
      fetchFlatServices: GET_ALL_WITH_BALANCE_LEGACY,
      getAccountArchive: GET_ARCHIVE_LEGACY,
      next_item: NEXT_LEGACY_ITEM,
    }),
    fetchFlatsBySearch(text) {
      flatAPI.legacy_search_by_text(text)
          .then(response => response.data)
          .then(data => {
            this.search_flats = data
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    getFlatFormError() {
      let text = ''
      const errors = []

      const fields = [
        {field: 'full_name', text: 'Повна назва'},
        {field: 'short_name', text: 'Коротка назва'},
        {field: 'person_id', text: 'Особовий рахунок'},
        {field: 'tax_type', text: 'Ставка ПДВ'},
        {field: 'street_id', text: 'Вулиця'},
        {field: 'building_id', text: 'Будинок'},
      ]

      if (!this.flat_form.is_legal_person) {
        fields.push(
            {field: 'code', text: 'Код ЄДРПОУ'}
        )
      } else {
        fields.push(
            {field: 'ident_code', text: 'Код ДРФО'}
        )
      }

      if (this.flat_form.disable) {
        fields.push(
            {field: 'disable_date', text: 'Дата закриття'}
        )
      }

      fields.forEach(item => {
        if (this.flat_form[item.field] === null || this.flat_form[item.field] === undefined) {
          errors.push(item.text)
        }
      })

      if (errors.length) {
        text = `Не заповнено: ${errors.join(', ')}`
        return text
      }
      return ''
    },
    afterReloadBuildings() {
      this.reload_buildings = false
    },
    closeModalEmit(payload) {
      if (payload.payload) {
        if (payload.payload.emit) {
          this.reload_buildings = true
        }
      }
    },
    get_response_person(category) {
      return this.print_data.response_persons?.[category]?.represent_name || 'Не вказано'
    },
    calcAfterChange() {
      if (this.$route.name === 'LegacyWorkPlace') {
        this.calcByFlat()
      }
    },
    calcByFlat() {
      this.calc_loading = true
      const person_hash = this.$route.params.person_hash
      this.calculateByFlat(person_hash)
          .then(e => {
                if (e) {
                  this.fetchFlatServices(person_hash)
                  this.getAccountArchive(person_hash)
                }
              }
          )
          .catch()
          .finally(() => {
            this.calc_loading = false
          })
    },
    streetChange(payload) {
      const text = (payload || {}).text || ''
      this.flat_form.street_id = (payload || {}).value || null

      this.$nextTick(() => {
        this.flat_form.legal_address = text
      })
    },
    buildingChange(payload) {
      const text = (payload || {}).text || ''

      this.$nextTick(() => {
        this.flat_form.legal_address = text
      })
    },
    flatSaveOrUpdate() {
      if (this.edit) {
        if (!this.formValid) {
          this.formValid = false
          this.$store.commit(ALERT_SHOW, {
            text: this.getFlatFormError(),
            color: 'error lighten-1'
          })
          return
        }
        this.update_loading = true

        let payload = {
          person_hash: this.flat.person_hash,
          person_id: this.flat_form.person_id,
          building_id: this.flat_form.building_id,
          full_name: this.flat_form.full_name,
          short_name: this.flat_form.short_name,
          is_legal_person: this.flat_form.is_legal_person,
          ident_code: this.flat_form.ident_code,
          ipn_tax: this.flat_form.ipn_tax,
          tax_type: this.flat_form.tax_type,
          fact_address: this.flat_form.fact_address,
          legal_address: this.flat_form.legal_address,
          code: this.flat_form.code,
          flat_number: this.flat_form.flat_number,
          city_id: this.flat_form.city_id,
          street_id: this.flat_form.street_id,
          disable: this.flat_form.disable,
          disable_date: this.flat_form.disable_date
        }

        flatAPI.check_legacy_person_id_id(
            {
              person_id: this.flat_form.person_id,
              person_hash: this.flat.person_hash,
            })
            .then(response => response.data)
            .then(data => {
              if (data) {
                this.$store.dispatch(UPDATE_LEGACY_FLAT, payload).then(res => {
                  if (res) {
                    this.edit = false
                    this.calcByFlat()
                  }
                })
              } else {
                this.$store.dispatch(ALERT_SHOW, { text: 'Абонент із таким особовим рахунком вже існує', color: 'error lighten-1' })
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            })
            .finally(() => {
              this.update_loading = false
            })
      } else {
        this.edit = true
      }
    },
    nextItem(person_hash, right) {
      const payload = {
        person_hash: person_hash,
        right: right
      }
      this.next_item(payload)
          .then(e => {
            if (e) {
              if (this.$route.params.person_hash !== e) {
                if (right) {
                  this.next_button_visible = true
                } else {
                  this.prev_button_visible = true
                }
                if (this.tab) {
                  this.$router.push({
                    name: `LegacyWorkPlace`,
                    params: {"person_hash": e.toString()},
                    query: {tab: this.tab.toString()}
                  })
                } else {
                  this.$router.push({name: `LegacyWorkPlace`, params: {"person_hash": e.toString()}})
                }
              } else {
                if (right) {
                  this.prev_button_visible = true
                  this.next_button_visible = false
                } else {
                  this.prev_button_visible = false
                  this.next_button_visible = true
                }
              }
            }
          })
    },
    openSearchWindow() {
      this.show_search_window = true
    },
    searchFlatByText(e) {
      if (e && e.length >= 2) {
        this.fetchFlatsBySearch(e)
      } else {
        this.search_flats = []
      }
    },
    openWorkPlace(payload) {
      if (!payload.person_hash) return
      if (this.tab) {
        this.$router.push({
          name: `LegacyWorkPlace`,
          params: {"person_hash": payload.person_hash},
          query: {tab: this.tab.toString()}
        })
      } else {
        this.$router.push({name: `LegacyWorkPlace`, params: {"person_hash": payload.person_hash}})
      }
    },
  },
  async created() {
    await this.fetchOne(this.$route.params.person_hash)
    await this.fetchTaxes({})

    if (this.$route.query.tab) {
      this.tab = +this.$route.query.tab
    }

    this.flat_form.full_name = this.flat.full_address
    this.flat_form.is_legal_person = this.flat.is_legal_person
    this.flat_form.short_name = this.flat.address
    this.flat_form.person_id = this.flat.person_id
    this.flat_form.code = this.flat.code
    this.flat_form.ident_code = this.flat.ident_code
    this.flat_form.tax_type = this.flat.tax_type
    this.flat_form.ipn_tax = this.flat.ipn_tax
    this.flat_form.building_id = this.flat.building_id
    this.flat_form.flat_number = this.flat.flat_number
    this.flat_form.legal_address = this.flat.legal_address
    this.flat_form.fact_address = this.flat.fact_address
    this.flat_form.street_id = this.flat.street_id
    this.flat_form.disable = this.flat.disable
    this.flat_form.disable_date = this.flat.disable_date
    this.flat_form.city_id = this.flat.city_id

    const {data} = await contractorAPI.get_print_data({person_hash: this.$route.params.person_hash})
    this.print_data = data
  }
}
</script>

<style scoped lang="scss">
$item-title-font-size: .82rem;
$item-title-font-color: #449c48;
$item-value-font-size: .9rem;
$item-value-font-color: #ebebeb;

.active-menu-item {
  background: lightgrey !important;
}

.custom-tabs-1 {
  display: flex;
  flex-direction: column;
  height: 100%;

  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
    flex: 1;
  }

  :deep(> div[role="tablist"]) {
    //background-color: transparent !important;
    height: 40px !important;
  }
}

.custom-tabs-2 {
  display: flex;
  flex-direction: column;
  height: 100%;

  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
    flex: 1;
  }

  :deep(> div[role="tablist"]) {
    background-color: #f5f5f5 !important;
    height: 30px !important;
  }

  :deep(.v-window.v-item-group) {
    height: 100%;
  }

  :deep(.v-window-item--active) {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  :deep(.v-window-item) {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}

.custom-tabs.hidden {
  :deep(.v-item-group) {
    height: auto !important;
    .v-slide-group__wrapper {
      display: none;
    }
    .v-slide-group__prev {
      display: none !important;
    }
    .v-slide-group__next {
      display: none !important;
    }
  }
}
.media-print {
  background-color: #ff1111 !important;
}
.legacy-box {
  .legacy-item {
    margin-bottom: 6px;
    .legacy-title {
      font-size: $item-title-font-size;
      font-weight: 500;
      color: #303030;
      margin-bottom: 2px;
    }
    .legacy-value {
      font-size: $item-value-font-size;
      color: #545454;
      background-color: $item-value-font-color;
      padding: 4px 4px 4px 8px;
      border-radius: 5px;

      .legacy-value-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 8px;


        .legacy-value-item {
          .legacy-value-item-title {
            font-size: $item-title-font-size;
            font-weight: 500;
            color: #303030;
            margin-bottom: 2px;
          }
          .legacy-value-item-value {
            font-size: $item-value-font-size;
            color: #545454;
            background-color: $item-value-font-color;
            padding: 4px 4px 4px 8px;
            border-radius: 5px;
          }
        }
      }

    }
  }
}
.tool {
  &:deep(.v-toolbar__content) {
    padding-right: 0 !important;
  }
}
</style>